import Vue from "vue";
import Router from "vue-router";

const Home = () => import("../views/home/Home.vue");
const About = () => import("../views/about/About.vue");
const News = () => import("../views/news/News.vue");
const Job = () => import("../views/job/Job.vue");
const Contact = () => import("../views/contact/Contact.vue");
const PrivacClause = () => import("../views/privacyClause/PrivacyClause.vue");
const ServiceAgreement = () =>
  import("../views/serviceAgreement/ServiceAgreement.vue");

// 安装插件
Vue.use(Router);

// 创建路由对象
const routes = [
  {
    path: "/",
    redirect: "/home"
  },
  {
    path: "/home",
    component: Home
  },
  {
    path: "/about",
    component: About
  },
  {
    path: "/news",
    component: News
  },
  {
    path: "/job",
    component: Job
  },
  {
    path: "/contact",
    component: Contact
  },
  {
    path: "/privacy_clause",
    component: PrivacClause
  },
  {
    path: "/serviceAgreement",
    component: ServiceAgreement
  }
];

const router = new Router({
  routes,
  mode: "hash"
});

export default router;
