<template>
  <div id="app">
    <nav-view />
    <router-view></router-view>
    <footer-view />
  </div>
</template>

<script>
import NavView from "components/content/navView/NavView";
import FooterView from "components/content/footerView/FooterView";
export default {
  name: "App",
  components: {
    NavView,
    FooterView
  }
};
</script>

<style>
@import url("./assets/css/reset.css");
</style>
