<template>
  <div class="footer-view">
    <div class="top bxb-p30">
      <router-link to="/privacy_clause">《风险隐私政策》</router-link>
      <router-link to="/serviceAgreement">《服务协议》</router-link>
      <span>违法和不良信息举报：086-0776-2624266</span>
      <span>官方邮箱：yuanlanxiaodai@outlook.com</span>
      <span>邮编：533000</span>
    </div>
    <div class="bottom bxb-p30">
      <span>版权所有：百色市远兰小额贷款有限责任公司 版权所有 </span>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.footer-view {
  background: #1f2329;
  text-align: center;
  color: #adadad;
  a,
  span {
    color: #adadad;
    padding: 0 20px;
  }
  .top {
    border-bottom: 1px solid rgba(255, 255, 255, 0.06);
  }
}
</style>
