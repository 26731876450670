<template>
  <div class="bxb-box nav-box">
    <div class="bxb-con flex justify-between">
      <div class="logo flex align-center">
        <img src="~assets/img/logo.png" alt="">
      </div>
      <div class="nav-list">
        <router-link to="/home">首页</router-link>
        <router-link to="/about">关于</router-link>
        <router-link to="/news">新闻</router-link>
        <router-link to="/job">招聘</router-link>
        <router-link to="/contact">联系</router-link>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    
  }
</script>

<style lang="scss" scoped>
.nav-box{
  height:80px;
  background:#fff;
  .nav-list{
    line-height: 80px;
    a{
      font-size:18px;
      margin:0 30px;
      color:#333;
      padding:0 0 5px;
      &.router-link-active{
        color:#597ef7;
        font-weight: bold;
      }
    }
  }
}
</style>